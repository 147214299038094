<template>
	<div id="serviceList" class="el-content">
		<a-space class="mb12">
			<a-button type="primary" v-has="{action:'wxapp_goods_server_add',plat:isShop}" @click="showEditService(0)">
				<i class="ri-add-line"></i>新增服务
			</a-button>
			<a-button type="danger" v-has="{action:'wxapp_goods_server_del',plat:isShop}" @click="deleteService(pks)">
				<i class="ri-delete-bin-line"></i>批量删除
			</a-button>
		</a-space>

		<a-table row-key="id" size="small" :pagination="false"
                 :data-source="serviceState.info.list"
                 :defaultExpandAllRows="true"
                 :row-selection="{ selectedRowKeys: pks, onChange:(e)=>{pks = e}  }"
                 :columns='[
                    {dataIndex:"id",title:"ID"},
                    {dataIndex:"name",title:"服务名称"},
                    {dataIndex:"rank",title:"排序"},
                    {dataIndex:"update_time",title:"最后更新时间"},
                    {dataIndex:"action",title:"操作",slots:{customRender:"action"}},
                 ]'
        >
			<template #action="{record}">
				<a-space>
					<kd-button
						type="primary"
						icon="ri-edit-2-line"
						title="编辑"
						@click="showEditService(record)"
						v-has="{action:'wxapp_goods_server_add',plat:isShop}">
					</kd-button>
					<kd-button
						type="danger"
						icon="ri-delete-bin-5-line"
						title="删除"
						@click="deleteService([record.id])"
						v-has="{action:'wxapp_goods_server_del',plat:isShop}">
					</kd-button>
				</a-space>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="serviceState.info.page"
				:total="serviceState.info.count"
				@showSizeChange="(p,e)=>{getService(serviceState.info.page,e)}"
				@change="(e)=>{getService(e,serviceState.info.limit)}"
			/>
		</div>
		<a-modal title="添加/编辑服务信息"
                 v-model:visible="esState.show"
                 width="600px"
                 @ok="saveService"
                 @cancel="esState.show = false"
        >
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="服务名称">
					<a-input v-model:value="esState.form.name"></a-input>
				</a-form-item>
				<a-form-item label="服务内容">
					<a-textarea v-model:value="esState.form.content" placeholder="服务内容" :rows="4" />
				</a-form-item>
				<a-form-item label="服务排序" >
					<a-input v-model:value="esState.form.rank" type="number"></a-input>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import {ref} from 'vue';
import { useService,useEditService } from "@/models/goods"
export default{
	name:'page-server-list',
	props:{
		isShop:{
			type:Number,
			value:0
		}
	},
	setup(){
	    let pks = ref([])
		let { serviceState ,getService } = useService()
        getService(1,10)
        // esState,showEditService,saveService,deleteService
        let esStateArr = useEditService({state:serviceState,getService})
		return{
            pks,
            serviceState,
            getService,
            ...esStateArr
		}
	},
}
</script>
